<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import axios from "axios";


/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "User List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "User List",
      items: [
        {
          text: "Contacts",
        },
        {
          text: "User List",
          active: true,
        },
      ],
      userList: [],
      coachList: [],
      totalRows: 1,
      currentPage: 1,
      lastVisible: null,
      perPage: 500,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      loadingUsers: false,
      endLoadMore: false,
      searchingUser: false,
      limit: 300,
      searchFilter: "premium",
      searchFilterOptions: ["premium", "basic", "all"],
      listFilter: "premium",
      listFilterOptions: ["premium", "basic", "all"],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "name",
        },
        
        {
          key: "last_training",
        },
        {
          key: "last_ticket",
        },
        {
          key: "last_evolution",
        },
         {
          key: "last_payment",
        },
        {
          role: "role",
        },
        {
          coach: "coach",
        },
      ],
      paiements: [],
      username_paypal1: "contact_api1.vicodellaforma.com",
      pass_paypal1: "6P3DADEXDQHSNX9B",
      api_signature1:
        "ABlLYlJXrwUWDhF71SK.kcrBWPRzANI9.ePZzQDvqWJV4LyGiGfF0EET",
      username_paypal2: "contact_api1.gabriellavico.com",
      pass_paypal2: "FCVTDCSE4R92BDZX",
      api_signature2:
        "AY.I3TgxD0nb5Y9GP.1e1R63HH8qAJqA2w3GkGq76Mm5jFRr08Ox67HT",
      //vieux stripe (contact@proteineandco.fr)
      api_stripe1:
        "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",
      //Stripe italie (contact@vicodellaforma.com)
      api_stripe2:
        "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",
      //Stripe Chypre (contact@gabriellavico.com)
      api_stripe3:
        "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",
      //Stripe Chypre Thrivecart
      api_stripe4:
        "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",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      //return this.userList.length;
      return 1;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    document.querySelectorAll('[data-bs-toggle="popover"]')

    firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        await this.getOptionsCoach();
        this.getUsers();
      }
    });
  },
  methods: {
    async popoverMethod(data) {
        document.getElementById("infopay-"+data.id).innerHTML = 'Loading...';
        var _payments = await this.getAllPayments(data);
        var _paiementsArray = JSON.parse(JSON.stringify(_payments));
        
        if(_paiementsArray.length > 0){

          var _total = this.getTotalPay(_payments);
          var _json = { 
            timestamp: _paiementsArray[0].timestamp*1000, 
            amount_due: _paiementsArray[0].amount_due, 
            methode: _paiementsArray[0].methode, 
            total: _total,
            status: _paiementsArray[0].status
            };

          //On met à jour la bdd avec le dernier paiement
          var db = firebase.firestore();
          db.collection("users")
          .doc(data.id)
          .update({ lastpaiement: _json});

          if(_paiementsArray[0].status == "succeeded"){
            document.getElementById("infopay-"+data.id).classList.add('btn');
            document.getElementById("infopay-"+data.id).classList.add('btn-sm');
            document.getElementById("infopay-"+data.id).classList.add('btn-outline-success');
           }
          else{
            document.getElementById("infopay-"+data.id).classList.add('btn');
            document.getElementById("infopay-"+data.id).classList.add('btn-sm');
            document.getElementById("infopay-"+data.id).classList.add('btn-outline-danger');
          }

          var _logopay;
          if(_paiementsArray[0].methode.includes("Stripe")) _logopay = 'fa-cc-stripe';
          else if(_paiementsArray[0].methode.includes("Stripe")) _logopay = 'fa-cc-paypal';
          else _logopay = 'fa-money-check-alt';

          document.getElementById("infopay-"+data.id).innerHTML = '<b>€' +  _paiementsArray[0].amount_due/100 + ' il ' + new Date(_paiementsArray[0].timestamp*1000).toLocaleDateString('it-IT') + ' <i class="fab '+_logopay+' fa-lg"></i></b><br/>Total: €'+_total;
          
        }
        else{
          document.getElementById("infopay-"+data.id).innerHTML = 'no payment';
        }
      },
    getAllPaymentAndUpdate(user){
      this.getAllPayments(user).then((result) => {
        console.log(JSON.parse(JSON.stringify(result)));
        var _paiements = JSON.parse(JSON.stringify(result));
        return _paiements;
        //RECUPÉRER LA 1ERE ENTRÉE DU TABLEAU QUI CORRESPOND AU DERNIER PAIEMENT
        //RECUPÉRER LA SOMME TOTAL PAYÉ
        //ENREGISTRÉ DANS LES PAIEMENT DE L'UTILISATEUR
        //AFFICHER
      });
    },
    getTotalPay(_paiements) {
      var total = 0;
      _paiements.forEach((pay) => {
        total += pay.amount_paid;
      });
      return total / 100;
    },
    /** PAYEMENTS */
    async getAllPayments(user) {
      console.log(user);
      //On réinitalise les paiements
      this.paiements = [];
      //On lance les function de payment
      await this.getPaymentsVirement(user);
      this.getPaymentsPaypal(
        this.username_paypal1,
        this.pass_paypal1,
        this.api_signature1,
        "Paypal (contact@vicodellaforma.com)",
        user
      );
      console.log("fini paypal 1");
      await this.getPaymentsPaypal(
        this.username_paypal2,
        this.pass_paypal2,
        this.api_signature2,
        "Paypal (contact@gabriellavico.com)",
        user
      );
      console.log("fini paypal 2");
      await this.getPaymentsStripe(
        this.api_stripe1,
        "Stripe (contact@proteineandco.fr)",
        user
      );
      console.log("fini stripe 1");
      await this.getPaymentsStripe(
        this.api_stripe2,
        "Stripe (contact@vicodellaforma.com)",
        user
      );
      console.log("fini stripe 2");
      await this.getPaymentsStripe(
        this.api_stripe3,
        "Stripe (contact@gabriellavico.com)",
        user
      );
      console.log("fini stripe 3");
      await this.getPaymentsStripe(
        this.api_stripe4,
        "Stripe Thrivecart (contact@gabriellavico.com)",
        user
      );
      console.log("fini stripe 4");
      return this.paiements;
    },
    getPaymentsOrder() {
      this.paiements = this.paiements.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp); // ascending
      });
    },
    getPayments() {
      const paiementsSrt = this.paiements.sort(
        (a, b) => b.timestamp - a.timestamp
      );
      return paiementsSrt;
    },
    paypalToArray(data, namePaypal) {
      var nbTransaction = data.split("L_TIMESTAMP").length - 1;
      const toArray = data
        .split("&")
        .map((p) => p.split("="))
        .reduce((obj, pair) => {
          const [key, value] = pair.map(decodeURIComponent);
          obj[key] = value;
          return obj;
        }, {});
      for (var i = 0; i < nbTransaction; i++) {
        //On transforme la date en timestamp
        var dateFull = toArray["L_TIMESTAMP" + i];
        var dateFullSplit = dateFull.split("T");
        var date = dateFullSplit[0];
        var spliteDate = date.split("-");
        var annee = spliteDate[0];
        var mois = spliteDate[1];
        var jour = spliteDate[2];
        var ts = new Date(Date.UTC(annee, mois - 1, jour)) / 1000;

        var transaction = {
          timestamp: ts,
          amount_due: toArray["L_AMT" + i] * 100,
          amount_paid: toArray["L_AMT" + i] * 100,
          status:
            toArray["L_STATUS" + i] == "Completed"
              ? "succeeded"
              : toArray["L_STATUS" + i],
          methode: namePaypal,
        };
        this.paiements.push(transaction);
        this.getPaymentsOrder();
      }
    },
    async getPaymentsVirement(user) {
      console.log("get virements");
      var db = firebase.firestore();
      await db
        .collection("users")
        .doc(user.id)
        .collection("paiements")
        .get()
        .then((virements) => {
          console.log("virements: " + JSON.stringify(virements));
          virements.forEach((virement) => {
            var dateSplit = virement.data().date.split("-");
            var ts =
              new Date(Date.UTC(dateSplit[0], dateSplit[1] - 1, dateSplit[2])) /
              1000;

            var transaction = {
              timestamp: ts,
              amount_due: virement.data().montant * 100,
              amount_paid: virement.data().montant * 100,
              status: "succeeded",
              methode: "Virement",
              id: virement.id,
            };
            this.paiements.push(transaction);
            this.getPaymentsOrder();
          });
        });
    },

    async getPaymentsPaypal(username, pass, signature, namePaypal, user) {
      var email_paypal = user.data.mail_paypal
        ? user.data.mail_paypal
        : user.data.email;

      const response = await fetch(
        "https://api-3t.paypal.com/nvp?USER=" +
          username +
          "&PWD=" +
          pass +
          "&SIGNATURE=" +
          signature +
          "&STARTDATE=2013-08-24T05:38:48Z&METHOD=TransactionSearch&VERSION=78&EMAIL=" +
          email_paypal
      );
      const data = await response.text();
      this.paypalToArray(data, namePaypal);
    },

    async getPaymentsStripe(api_key, nameStripe, user) {
      var email_stripe = user.data.mail_stripe
        ? user.data.mail_stripe
        : user.data.email;

      console.log(api_key + ' | ' + email_stripe); 

      var customer_ids = [];

      await axios
        .get("https://api.stripe.com/v1/customers?email=" + email_stripe, {
          headers: {
            Authorization: `Bearer ${api_key}`,
          },
        })
        .then((res) => {
          console.log("result stripe: " + JSON.stringify(res));
          res.data.data.forEach((element) => {
            customer_ids.push(element.id);
          });
        });

      //Maintenant qu'on a recupérer les id du client sur stripe, on va lister toutes les factures correspondant à ces client ID

      for await (const customer_id of customer_ids) {
        await axios
          .get("https://api.stripe.com/v1/charges?customer=" + customer_id, {
            headers: {
              Authorization: `Bearer ${api_key}`,
            },
          })
          .then((res) => {
            console.log("result stripe 2 " + JSON.stringify(res));
            res.data.data.forEach((payment) => {
              var transaction = {
                timestamp: payment.created,
                amount_due: payment.amount,
                amount_paid: payment.amount_captured,
                status: payment.status,
                methode: nameStripe,
              };
              this.paiements.push(transaction);
              this.getPaymentsOrder();
            });
          });
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetSearch() {
      this.userList = [];
      this.lastVisible = null;
      this.endLoadMore = false;
      this.getUsers();
    },
    async getOptionsCoach() {
      var db = firebase.firestore();
      await db
        .collection("users")
        .where("role", "array-contains", "coach")
        .get()
        .then((coachs) => {
          coachs.forEach((coach) => {
            this.coachList[coach.id] = coach.data().first_name + " " + coach.data().last_name;
          });
        });
      console.log(this.coachList)
    },
    searchUser() {
      var search = document.getElementById("search").value;

      if (search != "" && search != null) {
        this.userList = [];
        this.lastVisible = null;
        this.endLoadMore = true;
        this.searchingUser = true;
        var db = firebase.firestore();
        var requete = db.collection("users").orderBy("first_name", "asc");

        if (!JSON.parse(this.isAdmin))
          requete = requete.where(
            "coach",
            "==",
            firebase.auth().currentUser.uid
          );

        if (search.includes("@"))
          requete = requete.where("email", "==", search);
        else requete = requete.where("last_name", "==", search);

        requete
          .get()
          .then((users) => {
            users.forEach(async (user) => {
              this.userList.push({
                id: user.id,
                data: user.data(),
              });
              this.searchingUser = false;
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }
    },
    getUsers() {
      this.loadingUsers = true;
      var db = firebase.firestore();
      var requete = db.collection("users").orderBy("first_name", "asc");
      if (this.lastVisible != null) {
        requete = requete.startAfter(this.lastVisible);
      }
      if (!JSON.parse(this.isAdmin)){
        requete = requete.where("coach", "==", firebase.auth().currentUser.uid);
        console.log("non admin")
      }
        
      if (this.listFilter != "all")
        requete = requete.where("role", "array-contains", this.listFilter);

      requete
        .limit(this.limit)
        .get()
        .then((users) => {
          console.log("nb users: " + users.docs.length);
          this.lastVisible = users.docs[users.docs.length - 1];
          if (users.docs.length == 0 || users.docs.length < this.limit) {
            this.endLoadMore = true;
          }
          users.forEach(async (user) => {
            this.userList.push({
              id: user.id,
              data: user.data(),
            });
            this.loadingUsers = false;
          });
          //document.getElementById("savemodifications").style.display = "none";
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3" v-if="JSON.parse(isAdmin)">
                  <router-link :to="{ name: 'NewUser' }"
                    ><button type="button" class="btn btn-warning">
                      <i class="mdi mdi-plus me-2"></i> Add New
                    </button></router-link
                  >
                </div>
              </div>
            </div>
            <div class="row mt-4 mb-4" v-if="JSON.parse(isAdmin)">
              <div class="col-auto">
                <b-form-select
                  @change="resetSearch()"
                  v-model="listFilter"
                  :options="listFilterOptions"
                ></b-form-select>
              </div>
              <!-- Search -->
              <div class="col-auto align-end">
                <div class="row">
                  <div class="col-auto">Search :</div>
                  <div class="col-auto">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="search"
                      placeholder="Search (email or Last name)..."
                    />
                  </div>
                  <div class="col-auto">
                    <button
                      type="button"
                      class="btn btn-success btn-sm"
                      @click="searchUser()"
                    >
                      Go
                    </button>
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-danger btn-sm" @click="resetSearch()">
                      Reset
                    </button>
                  </div>
                  <div class="col-auto">
                    Total: {{ userList.length }}
                    </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                class="table table-centered table-nowrap"
                :items="userList"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(name)="data">
                  
                    <img
                      v-if="data.item.data.photo_profil"
                      :src="data.item.data.photo_profil"
                      alt
                      class="avatar-xs rounded-circle me-2"
                    />
                    <div
                      v-if="!data.item.data.photo_profil"
                      class="avatar-xs d-inline-block me-2"
                    >
                      <div
                        class="
                          avatar-title
                          bg-soft-primary
                          rounded-circle
                          text-primary
                        "
                      >
                        <i class="mdi mdi-account-circle m-0"></i>
                      </div>
                    </div>
                    <router-link
                    :to="{ name: 'Profile', params: { id: data.item.id } }"
                  >
                    {{ data.item.data.first_name }}
                    {{ data.item.data.last_name }}
                    </router-link>
                    <br/>
                    <a
                    :href="'mailto:' + data.item.data.email"
                    class="text-body"
                    >{{ data.item.data.email }}</a
                  >
                  <br/>
                  <span class="badge bg-warning text-dark" v-if="data.item.data.end_subscription_premium">End: {{ data.item.data.end_subscription_premium | moment('DD/MM/YYYY') }}</span>
                  
                </template>
                
                <template v-slot:cell(last_training)="data">
                  <button
                          v-if="data.item.data.last_training"
                          type="button"
                          class="btn btn-sm btn-outline-success"
                          :class="Math.round((Date.now() - data.item.data.last_training)/(24*3600000)) <7 ? 'btn-outline-success' : Math.round((Date.now() - data.item.data.last_training)/(24*3600000)) < 15 ? 'btn-outline-warning' : 'btn-outline-danger' "
                          @click="
                            JSON.parse(isAdmin)
                              ? null
                              : null
                          "
                        >
                          <i class="fas fa-dumbbell"></i> {{ data.item.data.last_training ? Math.round((Date.now() - data.item.data.last_training)/(24*3600000)) : "" }}
                        </button>
                </template>
                <template v-slot:cell(last_ticket)="data">
                  <button
                          v-if="data.item.data.last_ticket"
                          type="button"
                          class="btn btn-sm btn-outline-success"
                          :class="Math.round((Date.now() - data.item.data.last_ticket)/(24*3600000)) <7 ? 'btn-outline-success' : Math.round((Date.now() - data.item.data.last_ticket)/(24*3600000)) < 15 ? 'btn-outline-warning' : 'btn-outline-danger' "
                          @click="
                            JSON.parse(isAdmin)
                              ? null
                              : null
                          "
                        >
                          <i class="fas fa-envelope"></i> {{ data.item.data.last_ticket ? Math.round((Date.now() - data.item.data.last_ticket)/(24*3600000)) : "" }}
                        </button>
                </template>
                <template v-slot:cell(last_evolution)="data">
                  <button
                          v-if="data.item.data.last_evolution"
                          type="button"
                          class="btn btn-sm btn-outline-success"
                          :class="Math.round((Date.now() - data.item.data.last_evolution)/(24*3600000)) <7 ? 'btn-outline-success' : Math.round((Date.now() - data.item.data.last_evolution)/(24*3600000)) < 15 ? 'btn-outline-warning' : 'btn-outline-danger' "
                          @click="
                            JSON.parse(isAdmin)
                              ? null
                              : null
                          "
                        >
                          <i class="fas fa-camera"></i> {{ data.item.data.last_evolution ? Math.round((Date.now() - data.item.data.last_evolution)/(24*3600000)) : "" }}
                        </button>
                </template>
                <template v-slot:cell(last_payment)="data" v-if="JSON.parse(isAdmin)">
                  <div
                          :id="'infopay-'+data.item.id"
                          @click="popoverMethod(data.item)"
                        >
                        <div v-if="!data.item.data.lastpaiement" class="btn btn-sm btn-outline-info" type="button"><i class="fas fa-info"></i></div>
                        <div v-if="data.item.data.lastpaiement" type="button" class="btn btn-sm"
                        :class="data.item.data.lastpaiement.status == 'succeeded' && ((data.item.data.lastpaiement.amount_due / 100 < 160 && Date.now() - data.item.data.lastpaiement.timestamp < 2678400000 ) || (data.item.data.lastpaiement.amount_due / 100 > 160 && Date.now() - data.item.data.lastpaiement.timestamp < 7776000000 ))  ? 'btn-outline-success' : data.item.data.lastpaiement.status == 'succeeded' && ((data.item.data.lastpaiement.amount_due / 100 < 160 && Date.now() - Date.now() - data.item.data.lastpaiement.timestamp > 2678400000 ) || (data.item.data.lastpaiement.amount_due / 100 > 160 && data.item.data.lastpaiement.timestamp > 7776000000 )) ? 'btn-outline-warning' : 'btn-outline-danger'"
                        >
                          <b>€{{ data.item.data.lastpaiement.amount_due / 100 }} il {{data.item.data.lastpaiement.timestamp | moment("DD/MM/YYYY") }}
                          
                                  <i v-if="data.item.data.lastpaiement.methode.includes('Stripe')" class="fab fa-cc-stripe fa-lg"></i>
                                  <i v-if="data.item.data.lastpaiement.methode.includes('Paypal')" class="fab fa-cc-paypal  fa-lg"></i>
                                  <i v-if="data.item.data.lastpaiement.methode.includes('Virement')" class="fas fa-money-check-alt  fa-lg"></i>
                                  </b>
                                  <br/>Total: €{{data.item.data.lastpaiement.total }} </div>
                          
                        </div>
                </template>
                <template v-slot:cell(role)="data">
                  <div
                    class="d-inline p-2 bg-primary text-white"
                    v-for="(role, key) in data.item.data.role"
                    variant="warning"
                    :key="'role' + key + data.item.id"
                  >
                    {{ role }}
                  </div>
                </template>
                <template v-slot:cell(coach)="data">
                  <i class="fas fa-dumbbell"></i> {{ coachList[data.item.data.coach_programs] }}<br/>
                  <i class="fas fa-envelope"></i> {{ coachList[data.item.data.coach] }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-primary w-md waves-effect waves-light"
                  @click="getUsers()"
                  v-if="!this.endLoadMore"
                >
                  <span
                    v-if="this.loadingUsers"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="d-none d-sm-inline-block me-2"
                    >Load More Users</span
                  >
                  <i class="mdi mdi-send float-end"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
